import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

const About = ({ lang, aboutSection, aboutDescription, minosegPdf }) => {
	return (
		<StyledAbout id={lang === 'hu' ? 'bemutatkozás' : 'vorstellung'}>
			<div className='container'>
				<div className='aboutus'>
					<StaticImage
						src='../images/sgs_logo.png'
						alt='sgs-logo'
						className='sgs-logo'
					/>
					<h2 className='title'>
						{lang === 'hu'
							? aboutSection[0].node.title
							: aboutSection[1].node.title}
					</h2>
					<h4 className='welcome'>
						{lang === 'hu'
							? aboutSection[0].node.welcome
							: aboutSection[1].node.welcome}
					</h4>
					<p className='description'>
						{lang === 'hu'
							? aboutDescription[0].node.description
							: aboutDescription[1].node.description}
					</p>
					<a className='description-red' href={minosegPdf.url} target='_blank' rel='noreferrer'>
						{lang === 'hu'
							? "Kattints ide"
							: "Klicken Sie hier"}
					</a>
				</div>
				<div className='aboutpic'>
					<GatsbyImage
						className='about-img'
						image={aboutSection[0].node.aboutUsPic.gatsbyImageData}
						alt={
							lang === 'hu'
								? aboutSection[0].node.aboutUsPic.title
								: aboutSection[1].node.aboutUsPic.title
						}
					/>
				</div>
			</div>
		</StyledAbout>
	);
};

export default About;

const StyledAbout = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #ffff;
	.container {
		width: 100%;
		height: 600px;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}
	.title {
		color: rgb(57, 62, 70);
		font-size: 1.8rem;
		font-weight: 300;
		align-self: flex-start;
		margin-bottom: 0.5rem;
	}

	.welcome {
		font-size: 2rem;
		font-weight: 400;
		margin-bottom: 0.5rem;
		color: rgb(147, 2, 2);
	}

	.aboutus {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 2rem;
		max-width: 50%;
		position: relative;
	}

	.sgs-logo {
		width: 120px;
		height: auto;
		position: absolute;
		right: 1rem;
		top: 1rem;
	}

	.aboutpic {
		align-self: flex-end;
		height: 600px;
		max-width: 50%;
	}

	.about-img {
		width: 100%;
		height: 100%;
	}

	.description {
		color: rgb(57, 62, 70);
		margin-bottom: 0.4rem;
	}

	.description-red {
		color: rgb(147, 2, 2);
		margin-bottom: 0.4rem;
	}

	.description-red:hover{
		color: rgb(57, 62, 70);
	}
	

	@media only screen and (max-width: 1024px) {
		.aboutus {
			padding: 1rem;
		}
		.description {
			font-size: 0.9rem;
		}
	}
	@media only screen and (max-width: 768px) {
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.welcome {
			font-size: 2.5rem;
		}

		.aboutus {
			min-width: 100vw;
			padding: 3rem;
		}

		.aboutpic {
			display: none;
		}
	}
	@media only screen and (max-width: 480px) {
		.title {
			font-size: 1.5rem;
			margin-bottom: 0.5rem;
		}

		.welcome {
			font-size: 2rem;
		}
		.aboutus {
			padding: 2rem;
		}

		.sgs-logo {
		width: 70px;
		height: auto;
		right: 1rem;
		top: 1rem;
	}
	}
	@media only screen and (max-width: 320px) {
		.title {
			font-size: 1.5rem;
			margin-bottom: 0.3rem;
		}
		.description {
			font-size: 0.8rem;
		}
	}
`;
