import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Machines = ({ machines, foreignMachines, lang }) => {
	return (
		<StyledMachines id={lang === 'hu' ? 'géppark' : 'werkzeugmaschinen'}>
			<h1 className='title'>
				{lang === 'hu' ? 'Géppark' : 'Werkzeugmaschinen'}
			</h1>
			<div className='container'>
				<GatsbyImage
					image={machines[0].node.machinesBackground.gatsbyImageData}
					alt={machines[0].node.machineType}
					className='background-img'
				/>
				<div className="machines-container">
					<div className='machine-container'>
						{machines.map((machine) =>
							machine.node.node_locale === lang ? (
								<div className='machine' key={machine.node.machineType}>
									<h3 className='machine-title'>{machine.node.machineType}</h3>
									<p className='machine-description red'>
										{machine.node.machineDescription.machineDescription}
									</p>
									<p className='machine-description'>
										{machine.node.machineWorkspace.machineWorkspace}
									</p>
								</div>
							) : null
						)}
					</div>
					<p className='foreign-machines-title'>{lang === 'hu' ? "Közvetlen partnereinknél elérhető géppark:" : "Maschinenpark bei unseren Direktpartnern verfügbar:"}</p>
					<div className='machine-container'>
						{foreignMachines.map((machine) =>
							machine.node.node_locale === lang ? (
								<div className='machine' key={machine.node.machineType}>
									<h3 className='machine-title'>{machine.node.machineType}</h3>
									<p className='machine-description red'>
										{machine.node.machineDescription.machineDescription}
									</p>
									<p className='machine-description'>
										{machine.node.machineWorkspace.machineWorkspace}
									</p>
								</div>
							) : null
						)}
					</div>
				</div>
			</div>
		</StyledMachines>
	);
};

export default Machines;

const StyledMachines = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgb(255, 255, 255);
	padding: 2rem;
	.container {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
	}

	.title {
		color: rgb(57, 62, 70);
		font-size: 2rem;
		font-weight: 300;
	}

	.background-img {
		margin-right: 2rem;
	}

	.machine-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.machine {
		margin-bottom: 0.5rem;
		margin-right: 0.5rem;
	}

	.machine-title {
		color: rgb(57, 62, 70);
		font-size: 1.3rem;
		font-weight: 300;
	}

	.machine-description {
		color: rgb(57, 62, 70);
		line-height: 1.5rem;
		font-size: 0.8rem;
	}

	.foreign-machines-title{
		color: rgb(57, 62, 70);
	}

	.red {
		color: rgb(147, 15, 15);
		font-size: 0.8rem;
		font-weight: 500;
	}

	@media only screen and (max-width: 1200px) {
		.container {
			flex-direction: column;
		}
		.machine {
			margin: 1rem;
		}

		.foreign-machines-title{
		 	margin-left: 1rem;
	}
	}

	@media only screen and (max-width: 1024px) {
		padding: 3rem;

		.background-img {
			margin-right: 0rem;
		}
	}
	@media only screen and (max-width: 768px) {
		padding: 3rem 1rem;
	}
	@media only screen and (max-width: 480px) {
		padding: 1rem;
		.machine-container {
			grid-template-columns: repeat(1, 1fr);
		}

		.title {
			font-size: 1.5rem;
			margin-bottom: 1rem;
		}
	}
`;
