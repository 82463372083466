import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Services = ({ lang, servicesTexts, services }) => {
	return (
		<StyledServices
			id={lang === 'hu' ? 'szolgáltatásaink' : 'dienstleistungen'}
		>
			<div className='container'>
				<div className='titles'>
					<h2 className='title'>
						{lang === 'hu'
							? servicesTexts[0].node.title
							: servicesTexts[1].node.title}
					</h2>
					<h4 className='description'>
						{lang === 'hu'
							? servicesTexts[0].node.description
							: servicesTexts[1].node.description}
					</h4>
				</div>
				<div className='services-container'>
					{services.map((service) =>
						service.node.node_locale === lang ? (
							<div className='service' key={service.node.servicesTitle}>
								<div className='logo'>
									<GatsbyImage
										image={service.node.servicesLogo.gatsbyImageData}
										alt={`${service.node.servicesTitle}-logo`}
									/>
								</div>
								<h2 className='service-title'>{service.node.servicesTitle}</h2>
								<p className='service-description'>
									{service.node.servicesDescription}
								</p>
							</div>
						) : null
					)}
				</div>
			</div>
		</StyledServices>
	);
};

export default Services;

const StyledServices = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: rgb(200, 200, 200);
	width: 100%;
	padding: 5rem;

	.container {
		max-width: 1200px;
		text-align: center;
	}
	.title {
		color: rgb(57, 62, 70);
		font-size: 2rem;
		font-weight: 300;
		margin-bottom: 1rem;
	}

	.description {
		font-size: 2.5rem;
		font-weight: 400;
		margin-bottom: 2rem;
		color: rgb(147, 2, 2);
	}

	.services-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.service {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 5rem;
	}

	.logo {
		width: 80px;
		height: 80px;
		margin-bottom: 1rem;
	}

	.service-title {
		color: rgb(57, 62, 70);
		font-size: 2rem;
		font-weight: 300;
		margin-bottom: 0.5rem;
	}

	.service-description {
		color: rgb(57, 62, 70);
		margin-bottom: 0.4rem;
	}

	@media only screen and (max-width: 1024px) {
		padding: 3rem;
		.service {
			padding: 2rem;
		}
	}
	@media only screen and (max-width: 768px) {
		padding: 3rem 1rem;
		.service {
			padding: 1rem;
		}
	}
	@media only screen and (max-width: 480px) {
		.services-container {
			grid-template-columns: repeat(1, 1fr);
		}
		.title {
			font-size: 1.5rem;
			margin-bottom: 0.5rem;
		}

		.description {
			font-size: 2rem;
		}
	}
	@media only screen and (max-width: 320px) {
		.title {
			font-size: 1.5rem;
			margin-bottom: 0.3rem;
		}
		.description {
			font-size: 2rem;
		}
	}
`;
