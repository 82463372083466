import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Machines from '../components/Machines';
import Partners from '../components/Partners';
import Contact from '../components/Contact';
import Seo from '../components/Seo';

import '../styles/global.css';
import { graphql } from 'gatsby';

const Index = (rootQuery) => {
	const [lang, setLang] = useState('hu');
	const menuItems = rootQuery.data.allContentfulMenu.edges;
	const aboutSection = rootQuery.data.allContentfulAbout.edges;
	const aboutDescription =
		rootQuery.data.allContentfulAboutDescriptionTextNode.edges;
	const servicesTexts = rootQuery.data.allContentfulServicesTexts.edges;
	const services = rootQuery.data.allContentfulServices.edges;
	const machines = rootQuery.data.allContentfulMachines.edges;
	const foreignMachines = rootQuery.data.allContentfulForeignMachines.edges;
	const partners = rootQuery.data.allContentfulPartners.nodes;
	const contact = rootQuery.data.allContentfulContact.nodes;
	const minosegPdf = rootQuery.data.contentfulAsset.file;

	const setLanguage = (language) => {
		setLang(language);
	};

	return (
		<>
			<Seo title='CNC Power' />
			<Navbar
				className='navbar'
				menuItems={menuItems}
				lang={lang}
				setLanguage={setLanguage}
			/>
			<Hero lang={lang} rootQuery={rootQuery} />
			<About
				lang={lang}
				aboutSection={aboutSection}
				aboutDescription={aboutDescription}
				minosegPdf={minosegPdf}
			/>
			<Services lang={lang} servicesTexts={servicesTexts} services={services} />
			<Machines lang={lang} machines={machines} foreignMachines={foreignMachines}/>
			<Partners lang={lang} partners={partners} />
			<Contact lang={lang} contact={contact} menuItems={menuItems} />
		</>
	);
};

export default Index;

export const rootQuery = graphql`
	query {
		allContentfulMenu {
			edges {
				node {
					menuItem
					node_locale
				}
			}
		}
		allContentfulHero {
			edges {
				node {
					heroTitleLineOne
					heroTitleLineTwo
					node_locale
				}
			}
			nodes {
				heroImages {
					gatsbyImageData
					description
				}
			}
		}
		allContentfulAbout {
			edges {
				node {
					aboutUsPic {
						gatsbyImageData
						title
					}
					welcome
					title
				}
			}
		}
		allContentfulAboutDescriptionTextNode {
			edges {
				node {
					description
				}
			}
		}
		allContentfulServicesTexts {
			edges {
				node {
					title
					description
				}
			}
		}
		allContentfulServices(sort: { fields: contentfulid, order: ASC }) {
			edges {
				node {
					node_locale
					servicesDescription
					servicesTitle
					servicesLogo {
						gatsbyImageData
					}
				}
			}
		}
		allContentfulMachines(sort: { fields: contentfulid, order: ASC }) {
			edges {
				node {
					machineDescription {
						machineDescription
					}
					machineType
					machinesBackground {
						gatsbyImageData
					}
					node_locale
					machineWorkspace {
						machineWorkspace
					}
				}
			}
		}
		allContentfulForeignMachines(sort: { fields: contentfulid, order: ASC }) {
			edges {
				node {
					machineDescription {
						machineDescription
					}
					machineType
					node_locale
					machineWorkspace {
						machineWorkspace
					}
				}
			}
		}
		allContentfulPartners(sort: { fields: contentfulid, order: ASC }) {
			nodes {
				url
				title
				node_locale
				logo {
					gatsbyImageData(formats: WEBP, width: 200)
				}
			}
		}
		allContentfulContact(filter: { node_locale: { eq: "hu" } }) {
			nodes {
				phone
				email
				address
			}
		}
		contentfulAsset(file: {fileName: {eq: "Minőségpolitika.pdf"}}) {
			file {
			  url
			}
		}
	}
`;
