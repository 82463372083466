import React, { useEffect, useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import logo from '../images/logo-white.png';

const Navbar = ({ menuItems, lang, setLanguage }) => {
	const [open, setOpen] = useState(false);
	const [top, setTop] = useState(true);

	useEffect(() => {
		window.onscroll = () =>
			window.pageYOffset === 0 ? setTop(true) : setTop(false);
		return () => (window.onscroll = null);
	}, [top]);

	const handleLanguage = (language) => {
		setLanguage(language);
		setOpen(false);
	};

	return (
		<StyledNav
			style={
				top
					? { backgroundColor: 'rgba(0, 0, 0, 0.6)' }
					: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
			}
		>
			<div className='container'>
				<AnchorLink to='/#hero'>
					<img className='logo' src={logo} alt='logo' />
				</AnchorLink>
				<div className='navbar' id={!open ? '' : 'hidden'}>
					<div className='lang'>
						<button
							className={lang === 'hu' ? 'active language-btn' : 'language-btn'}
							onClick={() => handleLanguage('hu')}
						>
							hu
						</button>
						<button
							className={lang === 'de' ? 'active language-btn' : 'language-btn'}
							onClick={() => handleLanguage('de')}
						>
							de
						</button>
					</div>
					<div className='menuitems'>
						{menuItems.map((item) =>
							item.node.node_locale === lang ? (
								<button onClick={() => setOpen(false)} key={item.node.menuItem}>
									<AnchorLink
										className='menuitem'
										to={`/#${item.node.menuItem.toLowerCase().split(' ')[0]}`}
									>
										{item.node.menuItem.toUpperCase()}
									</AnchorLink>
								</button>
							) : null
						)}
					</div>
				</div>
				<FontAwesomeIcon
					className='mobile-menu'
					icon={!open ? faBars : faTimes}
					onClick={() => setOpen(!open)}
				/>
			</div>
		</StyledNav>
	);
};

export default Navbar;

const StyledNav = styled.div`
	position: sticky;
	top: 0;
	z-index: 20;
	transition: 0.5s all ease-in-out;

	.container {
		max-width: 1200px;
		height: 110px;
		margin: 0 auto;
		padding: 0 2rem;
		display: flex;
		align-items: center;
	}

	.logo {
		width: 100px;
		height: 100px;
		margin-top: 5px;
	}

	.navbar {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.lang {
		align-self: flex-end;
	}

	.language-btn {
		color: #ffff;
		font-size: 1rem;
		border: none;
		background: none;
		padding-left: 0.5rem;
		padding-bottom: 1rem;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.1s ease-in-out;
	}

	.language-btn:hover {
		transform: scale(1.1);
		color: rgb(147, 15, 15);
	}

	.active {
		transform: scale(1.1);
		color: rgb(147, 15, 15);
	}

	.menuitems {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.menuitem {
		color: #ffff;
		margin-left: 2rem;
		font-size: 1rem;
		font-weight: 800;
		transition: all 0.1s ease-in-out;
	}

	.menuitem:hover {
		color: rgb(131, 131, 131);
		cursor: pointer;
	}

	.menuitem:focus {
		color: rgb(147, 15, 15);
	}

	button {
		background: none;
		border: none;
	}

	.mobile-menu {
		display: none;
	}

	@media only screen and (max-width: 1024px) {
		.container {
			padding: 0 2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.mobile-menu {
			display: flex;
			min-width: 2rem;
			height: auto;
			transition: all 0.1s ease-in-out;
			cursor: pointer;
			color: #ffff;
		}
		.mobile-menu:hover {
			transform: scale(1.1);
		}
		.navbar {
			display: none;
		}

		.lang {
			margin-right: 2rem;
		}

		.language-btn {
			font-size: 1.5rem;
			margin-right: 0.5rem;
			padding-bottom: 0.5rem;
		}

		.menuitems {
			display: flex;
			flex-direction: column;
		}

		.menuitem {
			font-weight: 400;
			margin: 0;
		}

		button {
			margin-bottom: 0.3rem;
		}

		#hidden {
			position: absolute;
			left: 0;
			top: 110px;
			height: 220px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			transition: all 0.5s ease-in-out;
			background-color: rgba(0, 0, 0, 0.6);
		}
		#hidden .menuitem {
			font-size: 1.5rem;
		}
	}
	@media only screen and (max-width: 480px) {
		.container {
			padding: 0 1rem;
		}
	}
`;
