import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMapMarkedAlt,
	faPhoneAlt,
	faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import GoogleMap from './GoogleMap';
import logo from '../images/logo.png';
import sgsLogo from '../images/sgs_logo.png';
import styled from 'styled-components';

const Contact = ({ lang, contact, menuItems }) => {
	return (
		<StyledContact id={lang === 'hu' ? 'kapcsolat' : 'kontakt'}>
			<div className='container'>
				<h1 className='title'>{lang === 'hu' ? 'Kapcsolat' : 'Kontakt'}</h1>
				<h4 className='welcome'>{}</h4>
				<div className='contact-container'>
					<div className='contact'>
						<FontAwesomeIcon icon={faMapMarkedAlt} className='logo' />
						<h4>{contact[0].address}</h4>
					</div>
					<div className='contact'>
						<FontAwesomeIcon icon={faPhoneAlt} className='logo' />
						<a href={`tel:${contact[0].phone}`}>
							<h4>{contact[0].phone}</h4>
						</a>
					</div>
					<div className='contact'>
						<FontAwesomeIcon icon={faEnvelope} className='logo' />
						<a href={`mailto:${contact[0].email}`}>
							<h4>{contact[0].email}</h4>
						</a>
					</div>
				</div>
			</div>
			<div className='googlemap'>
				<GoogleMap className='map' />
			</div>
			<div className='footer'>
				<div className='footer-container'>
					<div className='footer-item'>
						<img className='logo' src={logo} alt='logo' />
					</div>
					<div className='footer-item'>
						<img className='logo' src={sgsLogo} alt='sgs-logo' />
					</div>
					<div className='footer-item'>
						<div className='menuitems'>
							<h4>{lang === 'hu' ? 'Menü' : 'Navigationsleiste'}</h4>
							{menuItems.map((item) =>
								item.node.node_locale === lang ? (
									<AnchorLink
										key={item.node.menuItem}
										className='menuitem'
										to={`/#${item.node.menuItem.toLowerCase().split(' ')[0]}`}
									>
										<p>{item.node.menuItem}</p>
									</AnchorLink>
								) : null
							)}
						</div>
					</div>
					<div className='footer-item'>
						<div className='contact-details'>
							<h4>{lang === 'hu' ? 'Kapcsolat' : 'Kontakt'}</h4>
							<p className='footer-contact-title'>CNC Power Kft.</p>
							<p>{contact[0].address}</p>
							<p>{contact[0].phone}</p>
							<p>{contact[0].email}</p>
						</div>
					</div>
				</div>
			</div>
		</StyledContact>
	);
};

export default Contact;

const StyledContact = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.container {
		width: 100%;
		text-align: center;
		padding: 1rem 0;
		background: rgb(255, 255, 255);
		filter: drop-shadow(0px 6px 3px #7e7e7e);
		z-index: 5;
	}

	.title {
		color: rgb(57, 62, 70);
		font-size: 2rem;
		font-weight: 300;
		margin-bottom: 2rem;
	}

	.contact-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		padding: 1rem 5rem;
	}

	.contact {
		display: flex;
		align-items: center;
		.logo {
			color: rgb(57, 62, 70);
			width: 50px;
			height: 50px;
			margin-right: 1rem;
		}
		h4 {
			color: rgb(147, 2, 2);
			font-size: 1.5rem;
			font-weight: 300;
		}
		h4:hover {
			color: rgb(57, 62, 70);
		}
	}

	.googlemap {
		width: 100%;
	}

	.footer {
		display: flex;
		align-items: center;
		justify-content: center;

		.footer-container {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			padding: 1rem 2rem;
		}

		.footer-item {
			justify-self: center;
			padding: 0 1rem;
		}

		.logo {
			width: 120px;
			height: 120px;
			margin: 0.5rem;
		}

		.contact-details {
			h4 {
				padding-top: 1rem;
				color: rgb(147, 2, 2);
			}

			p {
				color: rgb(57, 62, 70);
			}
		}
		.menuitems {
			h4 {
				padding-top: 1rem;
				color: rgb(147, 2, 2);
			}

			p {
				color: rgb(57, 62, 70);
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		.contact-container {
			padding: 0 2rem;
		}
		.contact {
			h4 {
				font-size: 1.2rem;
			}
		}
	}

	@media only screen and (max-width: 1024px) {
		.contact {
			.logo {
				width: 40px;
				height: 40px;
				margin-right: 0.5rem;
			}
			h4 {
				font-size: 1rem;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		.contact-container {
			flex-direction: column;
		}

		.contact {
			margin-bottom: 2rem;
			.logo {
				width: 50px;
				height: 50px;
				margin-right: 1rem;
			}
			h4 {
				font-size: 1.5rem;
			}
		}

		.footer {
			.footer-container {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				padding: 1rem;
			}

			.footer-item {
				padding: 0;
			}

			.logo {
				width: 100px;
				height: 100px;
			}
			.contact-details {
				margin-left: 1rem;
				h4 {
					padding-top: 0.5rem;
					color: rgb(57, 62, 70);
					font-size: 1rem;
				}

				p {
					color: rgb(57, 62, 70);
					font-size: 1rem;
				}
			}
			.menuitems {
				display: none;
			}
		}
	}

	@media only screen and (max-width: 480px) {
		.title {
			font-size: 1.5rem;
		}

		.contact-container {
			padding: 0 1rem;
		}

		.contact {
			margin-bottom: 1.5rem;
			.logo {
				width: 40px;
				height: 40px;
				margin-right: 0.5rem;
			}
			h4 {
				font-size: 1.2rem;
			}
		}

		.footer {
			.footer-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.footer-item {
			}

			.contact-details {
				margin: 0;
				text-align: center;
			}
		}
	}
`;
