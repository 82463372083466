import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Partners = ({ partners, lang }) => {
	return (
		<StyledPartners id={lang === 'hu' ? 'partnereink' : 'unsere'}>
			<h1 className='title'>
				{lang === 'hu' ? 'Partnereink' : 'Unsere Partner'}
			</h1>
			<div className='container'>
				{partners.map((partner) =>
					partner.node_locale === lang && partner.title !== "Minosegpolitika" ? (
						<div className='partner' key={partner.title}>
							<div className='logo-container'>
								<a href={partner.url} target='_blank' rel='noreferrer'>
									<GatsbyImage
										image={partner.logo.gatsbyImageData}
										className='logo'
										alt={partner.title}
									/>
								</a>
							</div>
							<h4 className='partner-title'>{partner.title}</h4>
						</div>
					) : null
				)}
			</div>
		</StyledPartners>
	);
};

export default Partners;

const StyledPartners = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgb(200, 200, 200);
	padding: 3rem;
	.container {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	.title {
		color: rgb(57, 62, 70);
		font-size: 2rem;
		font-weight: 300;
		margin-bottom: 3rem;
	}

	.partner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 2rem;
	}

	.logo-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 300px;
		height: 100px;
	}

	.logo-container {
		transition: 0.3s all ease-in-out;
		filter: grayscale(100%);
	}

	.logo-container:hover {
		transform: scale(1.1);
		filter: grayscale(0%);
	}

	.logo-container:hover + .partner-title {
		color: rgb(147, 2, 2);
	}

	.partner-title {
		color: rgb(57, 62, 70);
		font-size: 1.2rem;
		font-weight: 300;
		margin-bottom: 2rem;
		z-index: 10;
	}

	@media only screen and (max-width: 1024px) {
		.container {
			grid-template-columns: repeat(2, 1fr);
		}
		.logo-container {
			filter: grayscale(0%);
		}
	}

	@media only screen and (max-width: 768px) {
		padding: 2rem 0;
		margin-top: 2rem;

		.partner {
			margin-bottom: 1rem;
		}

		.title {
			margin-bottom: 1rem;
		}

		.logo-container {
			width: 200px;
		}
	}

	@media only screen and (max-width: 480px) {
		.container {
			grid-template-columns: repeat(1, 1fr);
		}
		.title {
			font-size: 1.5rem;
		}
	}
`;
