import React, { useEffect, useState, useCallback } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Hero = ({ rootQuery, lang, setLanguage }) => {
	const heroTitleHu = rootQuery.data.allContentfulHero.edges[0].node;
	const heroTitleDe = rootQuery.data.allContentfulHero.edges[1].node;
	const imagesHu = rootQuery.data.allContentfulHero.nodes[0].heroImages;
	const imagesDe = rootQuery.data.allContentfulHero.nodes[1].heroImages;
	const [currentBackgroundPosition, setCurrentBackgroundPosition] = useState(0);

	const nextBackgroundPosition = useCallback(() => {
		currentBackgroundPosition < imagesHu.length - 1
			? setCurrentBackgroundPosition(currentBackgroundPosition + 1)
			: setCurrentBackgroundPosition(0);
	}, [currentBackgroundPosition, imagesHu]);

	useEffect(() => {
		const timer = setTimeout(() => {
			nextBackgroundPosition();
		}, 5000);
		return () => clearTimeout(timer);
	}, [nextBackgroundPosition]);

	return (
		<StyledHero className='hero' id='hero'>
			<div className='container'>
				<GatsbyImage
					className='background'
					image={imagesHu[currentBackgroundPosition].gatsbyImageData}
					alt={
						lang === 'hu'
							? imagesHu[currentBackgroundPosition].description
							: imagesDe[currentBackgroundPosition].description
					}
				/>
				<h1 className='title'>
					{lang === 'hu'
						? heroTitleHu.heroTitleLineOne
						: heroTitleDe.heroTitleLineOne}
					<br />{' '}
					{lang === 'hu'
						? heroTitleHu.heroTitleLineTwo
						: heroTitleDe.heroTitleLineTwo}
				</h1>
			</div>
		</StyledHero>
	);
};

export default Hero;

const StyledHero = styled.div`
	height: 80vh;
	flex-direction: column;

	.container {
		height: 80%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	.title {
		font-size: 5rem;
		text-align: center;
		font-weight: 300;
		color: rgb(147, 2, 2);
		text-shadow: 0px 2px 2px #d1d1d1;
	}

	@media only screen and (max-width: 1024px) {
		.title {
			font-size: 4rem;
		}
	}

	@media only screen and (max-width: 768px) {
		.title {
			font-size: 3rem;
			font-weight: 400;
		}
	}

	@media only screen and (max-width: 480px) {
		.title {
			font-weight: 400;
			font-size: 2.5rem;
		}
	}
`;
