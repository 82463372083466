import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class GoogleMap extends React.Component {
	render() {
		const containerStyle = {
			position: 'relative',
			width: '100%',
			height: '400px',
		};

		const mapStyles = {
			width: '100%',
			height: '100%',
		};
		return (
			<Map
				google={this.props.google}
				zoom={11}
				containerStyle={containerStyle}
				style={mapStyles}
				initialCenter={{ lat: 47.68333, lng: 17.63512 }}
			>
				<Marker
					position={{ lat: 47.68156590441005, lng: 17.674961166288334 }}
				/>
			</Map>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyDOHk42-srKt8u8wxk-2HCLwPhTj-M8yq0',
})(GoogleMap);
